import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { CircularProgress, TablePagination } from '@mui/material'

import { ReservationList } from './ReservationList'
import { Reservation } from '../../../../../domain/entities/reservation'
import { useNotificationContext } from '../context-provider/notification-context-provider'
import { IGetReservations } from '../../../../../domain/usecases/get-reservations'

const useStyles = makeStyles()(() => ({
  progressBar: { display: 'flex', justifyContent: 'center' },
}))

type Props = {
  getReservations: IGetReservations
}

export const ReservationListLoader = ({ getReservations }: Props) => {
  const notificationContext = useNotificationContext()
  const [page, setPage] = useState(0)
  const [reservationPerPage, setReservationPerPage] = useState(5)
  const [count, setCount] = useState(0)
  const [reservations, setReservations] = useState<readonly Reservation[]>([])
  const [loading, setLoading] = useState(true)
  const { classes } = useStyles()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const response = await getReservations.get(page, reservationPerPage)
        setCount(response.count)
        setReservations(response.reservations)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setCount(0)
        setReservations([])
        notificationContext.notifyError("Les informations n'ont pas pu est chargées.")
      }
    })()
  }, [page, reservationPerPage])

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReservationPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return loading ? (
    <div className={classes.progressBar}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <ReservationList reservations={reservations} />
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        count={count}
        rowsPerPage={reservationPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}
