import React, { createContext, useState, useMemo, useContext } from 'react'
import { useHistory } from './useHistory'
import { useDebounce } from './useDebounce'

export type ReservationLocationState = {
  deposit?: Date
  pickup?: Date
}

export type FlyInformationContext = {
  deposit: Date | null
  setDeposit: (Date) => void

  pickup: Date | null
  setPickup: (Date) => void

  destination: string | null
  setDestination: (string) => void

  origin: string | null
  setOrigin: (string) => void

  depositFlyNumber: string | null
  setDepositFlyNumber: (string) => void

  pickupFlyNumber: string | null
  setPickupFlyNumber: (string) => void
}

const FlyInformationContextClass = createContext<FlyInformationContext>({
  deposit: null,
  setDeposit: () => {},
  pickup: null,
  setPickup: () => {},
  destination: null,
  setDestination: () => {},
  origin: null,
  setOrigin: () => {},
  depositFlyNumber: null,
  setDepositFlyNumber: () => {},
  pickupFlyNumber: null,
  setPickupFlyNumber: () => {},
})

export const useFlyInformationContext = (): FlyInformationContext => {
  return useContext(FlyInformationContextClass)
}

export const FlyInformationContextProvider = ({ children }) => {
  const { state: queryParams } = useHistory<ReservationLocationState>()
  const [deposit, setDeposit] = useState<Date | null>(queryParams?.deposit || null)
  const [pickup, setPickup] = useState<Date | null>(queryParams?.pickup || null)
  const debouncedDeposit = useDebounce<Date | null>(deposit, 500)
  const debouncedPickup = useDebounce<Date | null>(pickup, 500)
  const [destination, setDestination] = useState<string | null>(null)
  const [origin, setOrigin] = useState<string | null>(null)
  const [depositFlyNumber, setDepositFlyNumber] = useState<string | null>(null)
  const [pickupFlyNumber, setPickupFlyNumber] = useState<string | null>(null)
  const context = useMemo<FlyInformationContext>(
    () => ({
      deposit: debouncedDeposit,
      setDeposit,
      pickup: debouncedPickup,
      setPickup,
      destination,
      setDestination,
      origin,
      setOrigin,
      depositFlyNumber,
      setDepositFlyNumber,
      pickupFlyNumber,
      setPickupFlyNumber,
    }),
    [
      debouncedDeposit,
      setDeposit,
      debouncedPickup,
      setPickup,
      destination,
      setDestination,
      origin,
      setOrigin,
      depositFlyNumber,
      setDepositFlyNumber,
      pickupFlyNumber,
      setPickupFlyNumber,
    ]
  )

  return <FlyInformationContextClass.Provider value={context}>{children}</FlyInformationContextClass.Provider>
}
