import React, {useState} from "react"
import {makeStyles,} from "tss-react/mui"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import {isBrowser, navigateSafely} from "../../../../utils/safe-navigation"
import {useNotificationContext} from "../context-provider/notification-context-provider"
import {IForgotPassword} from "../../../../../domain/usecases/forgot-password";

const useStyles = makeStyles()(() =>
    ({
        root: {
            maxWidth: 600,
            margin: "auto",
        },
    })
)

function extractEmailFromUrl(): string | null {
    if (isBrowser()) {
        return window?.history?.state?.email
    } else {
        return null
    }
}

type Props = {
    forgotPassword: IForgotPassword
}

export const ForgotPasswordPage = ({forgotPassword}: Props) => {
    const notificationContext = useNotificationContext()
    const [email, setEmail] = useState(extractEmailFromUrl())
    const [error, setError] = useState<string | null>(null)
    const {classes} = useStyles()

    function sendEmail() {
        if (email !== null) {
            forgotPassword.submit({email})
                .then((_response) => {
                    notificationContext.notifySuccess("Un email vous a été envoyé");
                    navigateSafely("/app")
                })
                .catch((error) => {
                    if (error.status === 404) {
                        setError("Email introuvable");
                    } else {
                        notificationContext.notifyError("Une erreur est survenue");
                    }
                });
        }
    }

    return (
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Mot de passe oublié
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                            id="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormControl>
                    {error == null ? null : (
                        <Typography variant="body2" color="error" gutterBottom>
                            {error}
                        </Typography>
                    )}
                </CardContent>
                <CardActions>
                    <Button size="large" onClick={sendEmail}>
                        Valider
                    </Button>
                </CardActions>
            </Card>
        </div>
    )
}

