import React from 'react'
import ReservationDetails from '../../../presentation/common/components/app/ReservationDetails'
import { createRemoteGetReservation } from '../usecases/remote-get-reservation-factory'
import { createRemoteUpdateReservation } from '../usecases/remote-update-reservation-factory'
import { createRemoteVoucher } from '../usecases/remote-voucher-factory'

const createReservationDetailsPage: React.FC = () => {
  const remoteGetReservation = createRemoteGetReservation()
  const remoteUpdateReservation = createRemoteUpdateReservation()
  const remoteVoucher = createRemoteVoucher()

  return (
    <ReservationDetails
      getReservation={remoteGetReservation}
      updateReservation={remoteUpdateReservation}
      voucher={remoteVoucher}
    />
  )
}

export default createReservationDetailsPage
