import React from 'react'
import { CircularProgress, FormControlLabel, FormControl, FormGroup, Checkbox } from '@mui/material'

import Step from '../Step'
import { useOptionsContext } from './OptionsContextProvider'

const Loader = ({ isLoading, children }) => {
  return isLoading ? <CircularProgress /> : children
}

export default (props: any) => {
  const { isLoading, options, enable, disable } = useOptionsContext()

  const updateOption = event => {
    const optionId = parseInt(event.target.value)
    const checked = event.target.checked

    if (checked) {
      enable(optionId)
    } else {
      disable(optionId)
    }
  }

  return (
    <Step stepLabel="Prestations" {...props} stepComplete={true}>
      <FormControl component="fieldset">
        <FormGroup>
          <Loader isLoading={isLoading}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={option.enabled} onChange={updateOption} value={option.id} />}
                label={`${option.label} ${option.mandatory ? '(inclus)' : ''}`}
              />
            ))}
            {options.length === 0 && <p>Aucune prestations n'est disponible pour le moment.</p>}
          </Loader>
        </FormGroup>
      </FormControl>
    </Step>
  )
}
