import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { isNullOrEmpty } from '../../../../utils/form-validators'
import { navigateSafely } from '../../../../utils/safe-navigation'
import { useNotificationContext } from '../context-provider/notification-context-provider'
import { Grid } from '@mui/material'
import { PasswordField } from '../PasswordField'
import { IChangePassword } from '../../../../../domain/usecases/change-password'
import { InvalidCredentialsError } from '../../../../../domain/errors/invalid-credentials-error'
import { useAccountContext } from '../context-provider/account-context-provider'
import { useAuthorizationContext } from '../context-provider/authorization-context-provider'

type Props = {
  changePassword: IChangePassword;
}

export const ChangePasswordCard = ({ changePassword }: Props) => {
  const notificationContext = useNotificationContext()
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [oldPasswordError, setOldPasswordError] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [state, setState] = useState({
    oldPassword: '',
    password: '',
    confirmationPassword: '',
  })
  const { account } = useAccountContext()
  const { login } = useAuthorizationContext()

  const handleChange = (name: string) => (event: any) => {
    setState({ ...state, [name]: event.target.value })
  }

  const submit = async () => {
    if (!isNullOrEmpty(state.password) || !isNullOrEmpty(state.oldPassword)) {
      try {
        await changePassword.submit({
          oldPassword: state.oldPassword,
          newPassword: state.password,
        })
        setOldPasswordError(false)
        setConfirmPasswordError(false)
        setState({
          oldPassword: '',
          password: '',
          confirmationPassword: '',
        })
        await login(account.email, state.password)
        await navigateSafely('/app')
      } catch (error) {
        if (error instanceof InvalidCredentialsError) {
          setOldPasswordError(true)
        } else {
          notificationContext.notifyError('Les informations n\'ont pas pu enregistrées.')
        }
      }
    }
  }

  useEffect(() => {
    if (isNullOrEmpty(state.password) || isNullOrEmpty(state.confirmationPassword)) {
      // Some field are still empty
      setConfirmPasswordError(false)
      setCanSubmit(false)
    } else {
      setConfirmPasswordError(state.password !== state.confirmationPassword)
      setCanSubmit(state.password === state.confirmationPassword && !isNullOrEmpty(state.oldPassword))
    }
  }, [state.password, state.oldPassword, state.confirmationPassword])

  return (
    <Card>
      <CardContent>
        <Typography variant='h5' gutterBottom>
          Modifier mon mot de passe
        </Typography>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={12}>
            <PasswordField
              id='outlined-adornment-old-password'
              label='Ancien mot de passe'
              value={state.oldPassword}
              onChange={handleChange('oldPassword')}
              aria-describedby='confirm-password-error-text'
              error={oldPasswordError ? 'Le mot de passe ne correspond pas' : null}
            />
          </Grid>
          <Grid item sm xs={12}>
            <PasswordField
              id='outlined-adornment-new-password'
              label='Nouveau mot de passe'
              value={state.password || ''}
              onChange={handleChange('password')}
            />
          </Grid>
          <Grid item sm xs={12}>
            <PasswordField
              id='outlined-adornment-confirm-password'
              label='Confirmer mon nouveau mot de passe'
              value={state.confirmationPassword}
              onChange={handleChange('confirmationPassword')}
              aria-describedby='confirm-password-error-text'
              error={confirmPasswordError ? 'Les mots de passe ne correspondent pas' : null}
            />
          </Grid>
          <Grid item xs={12}>
            <Button size='large' variant='contained' color='primary' disabled={!canSubmit} onClick={submit}>
              Modifier
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
