import React from 'react'

import ReservationsPage from '../../../presentation/common/components/app/Reservations'
import { createRemoteGetReservations } from '../usecases/remote-get-reservations-factory'

const createMyReservationsPage: React.FC = () => {
  const remoteGetReservations = createRemoteGetReservations()

  return <ReservationsPage getReservations={remoteGetReservations} />
}

export default createMyReservationsPage
