import React from 'react'
import {makeStyles} from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Step from '../Step'
import {isEmailValid} from '../../../../utils/form-validators'
import {Grid} from '@mui/material'
import {useCreateReservationContext} from './CreateReservationContextProvider'

function valideFirstname(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

function valideLastname(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

function valideMobile(value: string | null | undefined): boolean {
    return !!value && value.length >= 7
}

function valideAddress(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

function valideZipcode(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

function valideCity(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

function valideCarBrand(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

function valideRegistration(value: string | null | undefined): boolean {
    return !!value && value.length >= 1
}

const useStyles = makeStyles()(() => ({
    divider: {
        margin: '16px 0',
    },
}))

export default (props: any) => {
    const {classes} = useStyles()
    const {
        reservation,
        setFirstname,
        setLastname,
        setEmail,
        setMobile,
        setAddress,
        setZipcode,
        setCity,
        setCarBrand,
        setRegistration,
        setClientName,
        setClientService,
        setClientAddress,
        setClientZipcode,
        setClientCity,
    } = useCreateReservationContext()

    const isFormComplete =
        valideFirstname(reservation.firstname) &&
        valideLastname(reservation.lastname) &&
        valideMobile(reservation.mobile) &&
        valideAddress(reservation.address) &&
        valideZipcode(reservation.zipcode) &&
        valideCity(reservation.city) &&
        valideCarBrand(reservation.carBrand) &&
        valideRegistration(reservation.registration) &&
        isEmailValid(reservation.email)

    return (
        <Step {...props} stepLabel="Information voyageur" stepComplete={isFormComplete}>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Prénom"
                        error={!valideFirstname(reservation.firstname)}
                        value={reservation.firstname}
                        onChange={event => setFirstname(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Nom"
                        error={!valideLastname(reservation.lastname)}
                        value={reservation.lastname}
                        onChange={event => setLastname(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Email"
                        type="email"
                        error={!isEmailValid(reservation.email)}
                        value={reservation.email}
                        onChange={event => setEmail(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Mobile"
                        type="tel"
                        error={!valideMobile(reservation.mobile)}
                        value={reservation.mobile}
                        onChange={event => setMobile(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Adresse"
                        type="text"
                        error={!valideAddress(reservation.address)}
                        value={reservation.address}
                        onChange={event => setAddress(event.target.value)}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Code postal"
                        type="text"
                        error={!valideZipcode(reservation.zipcode)}
                        value={reservation.zipcode}
                        onChange={event => setZipcode(event.target.value)}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Ville"
                        type="text"
                        error={!valideCity(reservation.city)}
                        value={reservation.city}
                        onChange={event => setCity(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Marque de voiture"
                        type="text"
                        error={!valideCarBrand(reservation.carBrand)}
                        value={reservation.carBrand}
                        onChange={event => setCarBrand(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label="Immatriculation"
                        type="text"
                        error={!valideRegistration(reservation.registration)}
                        value={reservation.registration}
                        onChange={event => setRegistration(event.target.value)}
                    />
                </Grid>
            </Grid>

            <Divider className={classes.divider}/>

            <Typography variant="subtitle2" style={{marginBottom: 16}}>
                Besoin d'une facture professionnelle ?
            </Typography>

            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Société"
                        type="text"
                        value={reservation.clientName}
                        onChange={event => setClientName(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Service"
                        type="text"
                        value={reservation.clientService}
                        onChange={event => setClientService(event.target.value)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Adresse de la société"
                        type="text"
                        value={reservation.clientAddress}
                        onChange={event => setClientAddress(event.target.value)}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Code postal"
                        type="text"
                        value={reservation.clientZipcode}
                        onChange={event => setClientZipcode(event.target.value)}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Ville"
                        type="text"
                        value={reservation.clientCity}
                        onChange={event => setClientCity(event.target.value)}
                    />
                </Grid>
            </Grid>
        </Step>
    )
}
