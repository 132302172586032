import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import ReservationStepDates from './ReservationStepDates'
import ReservationStepOptions from './ReservationStepOptions'
import ReservationStepInformations from './ReservationStepInformations'
import ReservationStepPayment from './ReservationStepPayment'
import ReservationPriceDetails from './ReservationPriceDetails'
import PromoCode from './PromoCode'
import ConfirmationStep from './ConfirmationStep'
import Stepper from '../Stepper'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { CreateReservationContextProvider } from './CreateReservationContextProvider'
import { OptionsContextProvider } from './OptionsContextProvider'
import { FlyInformationContextProvider } from './FlyInformationContextProvider'
import { IQuotes } from '../../../../../domain/usecases/quotes'
import { ICreateReservation } from '../../../../../domain/usecases/create-reservation'
import { ICreatePendingReservation } from '../../../../../domain/usecases/create-pending-reservation'
import { IGetOptions } from '../../../../../domain/usecases/get-options'

const useStyles = makeStyles()(() => ({
  container: {
    maxWidth: '1184px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

type Props = {
  quotes: IQuotes
  getOptions: IGetOptions
  createReservation: ICreateReservation
  createPendingReservation: ICreatePendingReservation
}

export default function index({ quotes, getOptions, createReservation, createPendingReservation }: Props) {
  const { classes } = useStyles()
  const [stepIndex, setStepIndex] = useState(0)

  return (
    <FlyInformationContextProvider>
      <OptionsContextProvider getOptions={getOptions}>
        <CreateReservationContextProvider
          quotes={quotes}
          createReservation={createReservation}
          createPendingReservation={createPendingReservation}
        >
          <Grid container spacing={2} className={classes.container}>
            <Grid item sm={8} xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h4" align="center" gutterBottom>
                    Réservation de votre parking
                  </Typography>
                  <Stepper stepIndex={stepIndex} onChange={setStepIndex} confirmationStep={ConfirmationStep}>
                    <ReservationStepDates />
                    <ReservationStepOptions />
                    <ReservationStepInformations />
                    <ReservationStepPayment hideButtons={true} />
                  </Stepper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={4} xs={12}>
              <ReservationPriceDetails />
              <PromoCode />
            </Grid>
          </Grid>
        </CreateReservationContextProvider>
      </OptionsContextProvider>
    </FlyInformationContextProvider>
  )
}
