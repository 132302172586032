import { Voucher } from '../../domain/usecases/voucher'
import { HttpClient } from '../network/http-client'

export class RemoteVoucher implements Voucher {
  constructor(private readonly baseUrl: string, private readonly httpClient: HttpClient) {
  }

  async openVoucher(reservationId: number): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.baseUrl}/voucher/${reservationId}`,
      method: 'get',
      responseType: 'blob',
    })

    if (window) {
      const blob = new window.Blob([httpResponse.body], { type: 'application/pdf' })
      const objectUrl = URL.createObjectURL(blob)
      window.open(objectUrl)
    }
  }
}
