import { CreateReservation, ICreateReservation } from '../../domain/usecases/create-reservation'
import { HttpClient } from '../network/http-client'
import { HttpStatusCode } from 'axios'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'
import { DateMapper } from '../mappers/date-mapper'

export class RemoteCreateReservation implements ICreateReservation {
  constructor(private readonly createUrl: string, private readonly httpClient: HttpClient) {}

  async create(params: CreateReservation.Params): Promise<CreateReservation.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.createUrl,
      method: 'post',
      body: {
        ...params,
        deposit: DateMapper.transform(params.deposit),
        pickup: DateMapper.transform(params.pickup),
      },
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return httpResponse.body
      case HttpStatusCode.Unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError()
    }
  }
}
