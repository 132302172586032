import React, { useState } from "react"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

import Step from "../Step"
import { isAAS } from "../../../../utils/web-site"

var termsOfUrlUrl: string = ""
var legalNotice: string = ""

if (isAAS()) {
  termsOfUrlUrl = "https://aeropark-documents.s3.eu-west-1.amazonaws.com/Conditions+ge%CC%81ne%CC%81rales+de+ventes-AAS.pdf"
  legalNotice = "https://aeropark-documents.s3.eu-west-1.amazonaws.com/Mentions+le%CC%81gales-AAS.pdf"
} else {
  termsOfUrlUrl = "https://aeropark-documents.s3-eu-west-1.amazonaws.com/CGV.pdf"
  legalNotice = "https://aeropark-documents.s3.eu-west-1.amazonaws.com/Mentions+le%CC%81gales+%26+Politique+de+Confidentialite%CC%81+Ae%CC%81ropark.pdf"
}

export default ({ ...props }) => {
  const [state, setState] = useState(false)

  const handleChange = (event: any) => {
    setState(event.target.checked)
  }

  return (
    <Step stepLabel="Un dernier petit effort" {...props} stepComplete={state}>
      <FormControlLabel
        control={<Checkbox checked={state} onChange={handleChange} />}
        label={
          <p>
            En créant un compte, vous acceptez nos{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={termsOfUrlUrl}
            >
              Conditions générales
            </a>{" "}
            et{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={legalNotice}
            >
              Politique de Confidentialité
            </a>
            .
          </p>
        }
      />
    </Step>
  )
}
