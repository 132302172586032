import React from 'react'
import MyAccount from '../../../presentation/common/components/app/MyAccount'
import { createRemoteUpdateCurrentAccount } from '../usecases/remote-update-current-account-factory'

const createMyAccountPage: React.FC = () => {
  const remoteUpdateCurrentAccount = createRemoteUpdateCurrentAccount()

  return <MyAccount updateCurrentAccount={remoteUpdateCurrentAccount} />
}

export default createMyAccountPage