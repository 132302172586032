import React from "react";
import { makeStyles,  } from "tss-react/mui";
import Stepper from "@mui/material/Stepper";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      width: "90%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    title: {
      fontSize: 14,
      fontWeight: "bold",
    },
  })
);

export default ({ children: steps, stepIndex, onChange, onComplete, confirmationStep }: any) => {
  const { classes } = useStyles();

  const handleNext = () => {
    onChange(stepIndex + 1);

    // Stepper completed
    if (stepIndex >= steps.length - 1 && onComplete) {
      onComplete();
    }
  };

  const handleBack = () => {
    onChange(stepIndex - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={stepIndex} orientation="vertical">
        {Array.isArray(steps)
          ? steps.map((stepComponent, index) => {
              return React.cloneElement(stepComponent, {
                key: index,
                isFirstStep: stepIndex === 0,
                handleBack: stepComponent.props.handleBack || handleBack,
                handleNext: stepComponent.props.handleNext || handleNext,
              });
            })
          : React.cloneElement(steps, {
              key: 0,
              isFirstStep: true,
              handleBack: steps.props.handleBack || handleBack,
              handleNext: steps.props.handleNext || handleNext,
            })}
      </Stepper>
      {stepIndex === steps.length && confirmationStep && React.createElement(confirmationStep)}
    </div>
  );
};
