import { Paper, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui'
import React from "react";

const useStyles = makeStyles()(() =>
  ({
    root: {
      margin: "90px auto",
      maxWidth: 900,
    },
    confirmationStepContainer: {
      margin: 24,
    },
  })
);

export default function ConfirmationStep() {
  const { classes } = useStyles();

  return (
    <div className={classes.confirmationStepContainer}>
      <Paper square elevation={0}>
        <Typography>Votre réservation a bien été enregistrée.</Typography>
      </Paper>
    </div>
  );
}
