import { HttpStatusCode } from 'axios'

import { HttpClient } from '../network/http-client'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { GetReservations, IGetReservations } from '../../domain/usecases/get-reservations'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'
import { Reservation } from '../../domain/entities/reservation'

export class RemoteGetReservations implements IGetReservations {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<GetReservationsResponse>) {
  }

  async get(page: number, itemPerPage: number): Promise<GetReservations.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params: {
        page: page,
        itemPerPage: itemPerPage,
      },
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return {
          count: httpResponse.body.count,
          reservations: httpResponse.body.reservations,
        }
      case HttpStatusCode.Unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError()
    }
  }
}

type GetReservationsResponse = {
  count: number
  reservations: readonly Reservation[]
}
