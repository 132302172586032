import React, { useState, useEffect } from "react";
import { makeStyles,  } from "tss-react/mui";
import TextField from "@mui/material/TextField";

import Step from "../Step";

const useStyles = makeStyles()((_theme) =>
  ({
    firstnameField: {
      width: "47%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    lastnameField: {
      width: "47%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    mobileField: {
      width: "97%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    addressField: {
      width: "47%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    zipcodeField: {
      width: "23%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    cityField: {
      width: "22%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    cardBrandField: {
      width: "47%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    registrationField: {
      width: "47%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
    proField: {
      width: "97%",
      marginRight: 8,
      marginLeft: 8,
      marginTop: 16,
    },
  })
);

// TODO: define props type
export default ({
  onChange,
  firstname,
  lastname,
  mobile,
  address,
  zipcode,
  city,
  carBrand,
  registration,
  ...props
}: any) => {
  const { classes } = useStyles();
  const [stepState, setStepState] = useState({
    complet: false,
    error: false,
  });

  // TODO: find event type
  const handleChange = (name: string) => (event: any) => {
    onChange({
      firstname: firstname,
      lastname: lastname,
      mobile: mobile,
      address: address,
      zipcode: zipcode,
      city: city,
      carBrand: carBrand,
      registration: registration,
      [name]: event.target.value,
    });
  };

  // Validate form
  useEffect(() => {
    if (
      !firstname ||
      0 === firstname.length ||
      !lastname ||
      0 === lastname.length ||
      !mobile ||
      0 === mobile.length ||
      !address ||
      0 === address.length ||
      !zipcode ||
      0 === zipcode.length ||
      !city ||
      0 === city.length
    ) {
      // Some field are still empty
      setStepState({ complet: false, error: false });
    } else {
      setStepState({ complet: true, error: false });
    }
  }, [firstname, lastname, mobile, address, zipcode, city]);

  return (
    <Step stepLabel="Mes informations" {...props} stepComplete={stepState.complet}>
      <TextField
        label="Prénom"
        variant="outlined"
        type="text"
        required
        className={classes.firstnameField}
        value={firstname}
        onChange={handleChange("firstname")}
      />

      <TextField
        label="Nom"
        type="text"
        variant="outlined"
        required
        className={classes.lastnameField}
        value={lastname}
        onChange={handleChange("lastname")}
      />

      <TextField
        label="Mobile"
        variant="outlined"
        required
        type="tel"
        className={classes.mobileField}
        value={mobile}
        onChange={handleChange("mobile")}
      />

      <TextField
        label="Adresse"
        type="text"
        required
        variant="outlined"
        className={classes.addressField}
        value={address}
        onChange={handleChange("address")}
      />

      <TextField
        label="Code postal"
        type="text"
        required
        variant="outlined"
        className={classes.zipcodeField}
        value={zipcode}
        onChange={handleChange("zipcode")}
      />

      <TextField
        label="Ville"
        type="text"
        required
        variant="outlined"
        className={classes.cityField}
        value={city}
        onChange={handleChange("city")}
      />

      <TextField
        label="Modèle de voiture"
        type="text"
        variant="outlined"
        className={classes.cardBrandField}
        value={carBrand}
        onChange={handleChange("carBrand")}
      />

      <TextField
        label="Immatriculation"
        type="text"
        variant="outlined"
        className={classes.registrationField}
        value={registration}
        onChange={handleChange("registration")}
      />
      {/* 
            <FormControlLabel
                className={classes.proField}
                control={
                    <Checkbox checked={true} onChange={handleChange('pro')} />
                }
                label="Client professionnel ?"
            /> */}
    </Step>
  );
};
