import React from 'react';
import {createRemoteChangePassword} from "../usecases/remote-change-password-factory";
import ChangePassword from "../../../presentation/common/components/app/ChangePassword";

const createChangePasswordPage: React.FC = () => {
    const remoteChangePassword = createRemoteChangePassword();

    return (
        <ChangePassword changePassword={remoteChangePassword}/>
    )
};

export default createChangePasswordPage;