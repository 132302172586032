import { HttpStatusCode } from 'axios'
import { HttpClient } from '../network/http-client'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { UnauthorizedError } from '../../domain/errors/unauthorized-error'
import { IUpdateCurrentAccount, UpdateCurrentAccount } from '../../domain/usecases/update-current-account'

export class RemoteUpdateCurrentAccount implements IUpdateCurrentAccount {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {
  }

  async update(account: UpdateCurrentAccount.Model): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      body: account,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return httpResponse.body
      case HttpStatusCode.Unauthorized:
        throw new UnauthorizedError()
      default:
        throw new UnexpectedError()
    }
  }
}