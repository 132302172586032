import { HttpStatusCode } from 'axios'

import { HttpClient } from '../network/http-client'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { ChangePassword, IChangePassword } from '../../domain/usecases/change-password'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'

export class RemoteChangePassword implements IChangePassword {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient,
  ) {
  }

  async submit(params: ChangePassword.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      body: params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return
      case HttpStatusCode.Unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError()
    }
  }
}
