import React, { useState } from 'react'
import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Link } from 'gatsby'
import { navigateSafely } from '../../../../utils/safe-navigation'
import { PasswordField } from '../PasswordField'
import { useAuthorizationContext } from '../context-provider/authorization-context-provider'

const useStyles = makeStyles()(() => ({
  root: {
    marginTop: 0,
  },
  container: {
    maxWidth: '1184px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  marginTop: {
    marginTop: '16px',
  },
}))

type Props = {}

export const Login: React.FC<Props> = () => {
  const { classes } = useStyles()
  const { login } = useAuthorizationContext()
  const [state, setState] = useState({
    username: '',
    password: '',
  })
  const [credentialError, setCredentialError] = useState(false)

  const handleChange = (prop: string) => (event: any) => {
    setState({ ...state, [prop]: event.target.value })
  }

  const handleLogin = async (): Promise<void> => {
    try {
      await login(state.username, state.password)

      setCredentialError(false)
      await navigateSafely('/app')
    } catch (error) {
      setCredentialError(true)
    }
  }

  const handleCreateAccount = () => navigateSafely('/app/create-account')

  return (
    <div className={classes.container}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" color="primary" align="center" gutterBottom>
            Se connecter
          </Typography>
          <TextField
            autoFocus
            id="username"
            className={classes.marginTop}
            type="email"
            variant="outlined"
            value={state.username}
            error={credentialError}
            helperText={credentialError ? 'identifiant ou mot de passe incorrect' : null}
            onChange={handleChange('username')}
            label="Adresse email"
            fullWidth
          />
          <PasswordField
            id="outlined-adornment-password"
            label="Password"
            value={state.password}
            onChange={handleChange('password')}
            className={classes.marginTop}
            error={credentialError ? 'identifiant ou mot de passe incorrect' : null}
          />
          <div className={classes.marginTop}>
            <Link to="/app/forgot-password" state={{ email: state.username }}>
              Mot de passe oublié ?
            </Link>
          </div>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="secondary" size="medium" onClick={handleCreateAccount}>
            S'inscrire
          </Button>
          <Button variant="contained" color="secondary" size="medium" onClick={handleLogin}>
            Se connecter
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
