import { isBrowser } from '../../../../utils/safe-navigation'

export type History<T> = {
  state: T
}

export const useHistory = <T>(): History<T> => {
  if (isBrowser()) {
    return window.history || {}
  } else {
    return {
      state: {} as T,
    }
  }
}
