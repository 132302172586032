import React, { useState } from 'react'
import { Link } from 'gatsby'
import { makeStyles } from 'tss-react/mui'
import { Button, Divider, Grid, TextField, Typography } from '@mui/material'
import { isNullOrEmpty } from '../../../../utils/form-validators'
import { Account } from '../../../../../domain/entities/account'

const useStyles = makeStyles()(() => ({
  container: {
    maxWidth: '1184px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    marginTop: 16,
    marginBottom: 24,
  },
  divider: {
    margin: 16,
  },
}))

type Props = {
  account?: Account
  onSubmit: (user: Account) => void
}

export const MyAccountForm = ({ account, onSubmit }: Props) => {
  const { classes } = useStyles()
  const [state, setState] = useState<any>(account || {})

  const handleChange = (name: string) => (event: any) => {
    setState({ ...state, [name]: event.target.value })
  }

  return (
    <div className={classes.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Mon compte
      </Typography>
      <Typography variant="h5" gutterBottom className={classes.title}>
        Mes informations
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Prénom"
            type="text"
            error={isNullOrEmpty(state.firstname)}
            value={state.firstname || ''}
            onChange={handleChange('firstname')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Nom"
            type="text"
            error={isNullOrEmpty(state.lastname)}
            value={state.lastname || ''}
            onChange={handleChange('lastname')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            disabled
            variant="outlined"
            label="Email"
            fullWidth
            type="text"
            error={isNullOrEmpty(state.email)}
            value={state.email || ''}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Numéro de mobile"
            type="text"
            error={isNullOrEmpty(state.mobile)}
            value={state.mobile || ''}
            onChange={handleChange('mobile')}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <TextField
            fullWidth
            required
            variant="outlined"
            label="Adresse"
            type="text"
            error={isNullOrEmpty(state.address)}
            value={state.address || ''}
            onChange={handleChange('address')}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <TextField
            fullWidth
            required
            variant="outlined"
            label="Code postal"
            type="text"
            error={isNullOrEmpty(state.zipcode)}
            value={state.zipcode || ''}
            onChange={handleChange('zipcode')}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <TextField
            fullWidth
            required
            variant="outlined"
            label="Ville"
            type="text"
            error={isNullOrEmpty(state.city)}
            value={state.city || ''}
            onChange={handleChange('city')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Marque de voiture"
            type="text"
            value={state.carBrand || ''}
            onChange={handleChange('carBrand')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Immatriculation"
            type="text"
            value={state.registration || ''}
            onChange={handleChange('registration')}
          />
        </Grid>
      </Grid>

      <Divider variant="middle" className={classes.divider} />

      <Typography variant="h5" gutterBottom className={classes.title}>
        Espace réservé aux professionnels
      </Typography>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            label="Nom de la société"
            fullWidth
            type="text"
            value={state.clientName || ''}
            onChange={handleChange('clientName')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            variant="outlined"
            label="Service"
            fullWidth
            type="text"
            value={state.clientService || ''}
            onChange={handleChange('clientService')}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Adresse"
            type="text"
            value={state.clientAddress || ''}
            onChange={handleChange('clientAddress')}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Code postal"
            type="text"
            value={state.clientZipcode || ''}
            onChange={handleChange('clientZipcode')}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Ville"
            type="text"
            value={state.clientCity || ''}
            onChange={handleChange('clientCity')}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="center" alignItems="center">
          <Grid item sm={3} xs={12}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              disabled={
                isNullOrEmpty(state.firstname) ||
                isNullOrEmpty(state.lastname) ||
                isNullOrEmpty(state.mobile) ||
                isNullOrEmpty(state.address) ||
                isNullOrEmpty(state.zipcode) ||
                isNullOrEmpty(state.city)
              }
              onClick={() => onSubmit(state)}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Link color="primary" to="/app/change-password">
            Modifier mon mot de passe
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}
