import { HttpStatusCode } from 'axios'

import { GetOptions, IGetOptions } from '../../domain/usecases/get-options'
import { HttpClient } from '../network/http-client'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { DateMapper } from '../mappers/date-mapper'

export class RemoteGetOptions implements IGetOptions {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {}

  async get(params: GetOptions.Params): Promise<readonly GetOptions.Model[]> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params: {
        deposit: DateMapper.transform(params.deposit),
        pickup: DateMapper.transform(params.pickup),
      },
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return httpResponse.body
      case HttpStatusCode.Unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError()
    }
  }
}
