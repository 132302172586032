import { CreateAccount, ICreateAccount } from '../../domain/usecases/create-account'
import { HttpClient } from '../network/http-client'
import { HttpStatusCode } from 'axios'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { EmailAddressConflictError } from '../../domain/errors/email-address-conflict-error'

export class RemoteCreateAccount implements ICreateAccount {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {
  }

  async create(params: CreateAccount.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.Ok:
        return
      case HttpStatusCode.Conflict:
        throw new EmailAddressConflictError()
      default:
        throw new UnexpectedError()
    }
  }
}