import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Card, CardContent, CircularProgress, Container } from '@mui/material'
import { NumberParam, useQueryParam } from 'use-query-params'

import { isBrowser, navigateSafely } from '../../../../utils/safe-navigation'
import { IGetReservation } from '../../../../../domain/usecases/get-reservation'
import { ReservationContextProvider, useReservationContext } from '../context-provider/reservation-context-provider'
import ReservationForm from './ReservationForm'
import { IUpdateReservation } from '../../../../../domain/usecases/update-reservation'
import { Voucher } from '../../../../../domain/usecases/voucher'

function getState() {
  if (isBrowser()) {
    return window.history?.state || {}
  } else {
    return {}
  }
}

const useStyles = makeStyles()(() => ({
  root: {
    marginBottom: 20,
  },
  progressBar: { display: 'flex', justifyContent: 'center' },
}))

const Content = () => {
  const { classes } = useStyles()
  const { isLoading, reservation, update, openVoucher } = useReservationContext()

  return (
    <Container>
      <Card className={classes.root}>
        <CardContent>
          {isLoading || reservation == null ? (
            <div className={classes.progressBar}>
              <CircularProgress />
            </div>
          ) : (
            <ReservationForm reservation={reservation} onUpdate={update} onOpenVoucher={openVoucher} />
          )}
        </CardContent>
      </Card>
    </Container>
  )
}

type Props = {
  getReservation: IGetReservation
  updateReservation: IUpdateReservation
  voucher: Voucher
}

export default function index({ getReservation, updateReservation, voucher }: Props) {
  const historyState = getState()
  const [idQueryParam] = useQueryParam('id', NumberParam)
  const reservationId = historyState.reservationId || idQueryParam

  if (reservationId === null || reservationId === undefined) {
    navigateSafely('/app/reservations')
  }

  return (
    <ReservationContextProvider
      getReservation={getReservation}
      updateReservation={updateReservation}
      voucher={voucher}
      reservationId={reservationId}
    >
      <Content />
    </ReservationContextProvider>
  )
}
