import { HttpStatusCode } from 'axios'
import { HttpClient } from '../network/http-client'
import { UnexpectedError } from '../../domain/errors/unexpected-error'
import { GetCurrentAccount, IGetCurrentAccount } from '../../domain/usecases/get-current-account'
import { UnauthorizedError } from '../../domain/errors/unauthorized-error'

export class RemoteGetCurrentAccount implements IGetCurrentAccount {
    constructor(private readonly url: string, private readonly httpClient: HttpClient) {
    }

    async getCurrentAccount(): Promise<GetCurrentAccount.Model> {
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: 'get',
        })

        switch (httpResponse.statusCode) {
            case HttpStatusCode.Ok:
                return httpResponse.body
            case HttpStatusCode.Unauthorized:
                throw new UnauthorizedError()
            default:
                throw new UnexpectedError()
        }
    }
}