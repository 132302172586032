import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Card, CardContent, CircularProgress, Container } from '@mui/material'

import { MyAccountForm } from './MyAccountForm'
import { useAccountContext } from '../context-provider/account-context-provider'
import { IUpdateCurrentAccount } from '../../../../../domain/usecases/update-current-account'
import { useNotificationContext } from '../context-provider/notification-context-provider'

const useStyles = makeStyles()(() => ({
  root: {
    marginBottom: 20,
  },
  progressBar: { display: 'flex', justifyContent: 'center' },
}))

type Props = {
  updateCurrentAccount: IUpdateCurrentAccount
}

const MyAccountPage = ({ updateCurrentAccount }: Props) => {
  const { account, setAccount } = useAccountContext()
  const { notifyError, notifySuccess } = useNotificationContext()
  const { classes } = useStyles()

  async function submit(account: any) {
    try {
      await updateCurrentAccount.update(account)
      setAccount(account)
      notifySuccess('Les informations ont été enregistrées.')
    } catch (e) {
      notifyError('Une erreur est survenue')
    }
  }

  return (
    <Container>
      <Card className={classes.root}>
        <CardContent>
          {account ? (
            <MyAccountForm account={account} onSubmit={submit} />
          ) : (
            <div className={classes.progressBar}>
              <CircularProgress />
            </div>
          )}
        </CardContent>
      </Card>
    </Container>
  )
}

export default MyAccountPage
