import {HttpStatusCode} from "axios";

import {HttpClient} from "../network/http-client";
import {UnexpectedError} from "../../domain/errors/unexpected-error";
import {ForgotPassword, IForgotPassword} from "../../domain/usecases/forgot-password";

export class RemoteForgotPassword implements IForgotPassword {
    constructor(
        private readonly url: string,
        private readonly httpClient: HttpClient
    ) {
    }

    async submit(
        params: ForgotPassword.Params
    ): Promise<void> {
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: 'post',
            body: params,
        });

        switch (httpResponse.statusCode) {
            case HttpStatusCode.Ok:
                return;
            default:
                throw new UnexpectedError();
        }
    }
}
