import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useCreateReservationContext } from './CreateReservationContextProvider'

const useStyles = makeStyles()(() => ({
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  row: {
    borderBottom: 0,
  },
  prestationTitle: {
    marginTop: 16,
    marginBottom: 8,
  },
  invalidePrice: {
    textDecoration: 'line-through',
  },
}))

const Price = ({ value, ...props }) => <span {...props}>{((value || 0) / 100).toFixed(2)} €</span>

const Title = ({ title }) => (
  <TableRow>
    <TableCell colSpan={3}>
      <Typography variant="subtitle1" color="primary" gutterBottom>
        {title}
      </Typography>
    </TableCell>
  </TableRow>
)

const Separator = () => (
  <TableRow>
    <TableCell
      colSpan={3}
      style={{
        height: 16,
        borderBottom: 0,
      }}
    />
  </TableRow>
)

const DetailLine = ({ label, price }) => (
  <TableRow>
    <TableCell component="th" scope="row" colSpan={2} style={{ borderBottom: 0 }}>
      {label}
    </TableCell>
    <TableCell align="right" style={{ borderBottom: 0 }}>
      <Price value={price} />
    </TableCell>
  </TableRow>
)

export default () => {
  const { classes } = useStyles()
  const { prices } = useCreateReservationContext()

  return (
    <Card>
      <CardContent>
        <Table padding="none">
          <TableBody>
            <Title title="Forfait" />
            <Separator />
            <DetailLine label={(prices.parkDays || 0) + ' jour(s) de parking'} price={prices.parkPrice} />
            <Separator />

            <Title title="Prestations" />
            <Separator />
            {prices &&
              prices.options &&
              prices.options.map((option, index) => (
                <DetailLine key={index} label={option.label} price={option.price} />
              ))}

            <Separator />
            {prices.totalPrice === prices.bonusPrice ? null : (
              <TableRow>
                <TableCell rowSpan={1} className={classes.row} />
                <TableCell rowSpan={1} className={classes.row} />
                <TableCell align="right" className={classes.row}>
                  <Price className={classes.invalidePrice} value={prices.totalPrice} />
                </TableCell>
              </TableRow>
            )}
            <DetailLine label={'Total'} price={prices.bonusPrice} />

            {prices.promoCode && (
              <DetailLine label={'Code promo: ' + prices.promoCode} price={(prices.promoCodeReduction || 0) * -1} />
            )}

            <DetailLine label={'Restant à payer'} price={prices.remainingPrice} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
