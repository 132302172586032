import React, { useState } from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'
import SendIcon from '@mui/icons-material/Send'
import { useCreateReservationContext } from './CreateReservationContextProvider'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  resultIcon: {
    margin: '0px 10px',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export default () => {
  const { classes } = useStyles()
  const { reservation, prices, setPromoCode } = useCreateReservationContext()
  const [code, setCode] = useState<string>('')
  const error = reservation.promoCode && reservation.promoCode.length > 0 && prices.promoCode !== reservation.promoCode

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Code promo"
        inputProps={{ 'aria-label': 'Code promo' }}
        value={code}
        onChange={e => setCode(e.target.value)}
      />
      {error ? (
        <ErrorIcon className={classes.resultIcon} color="error" />
      ) : (
        prices.promoCode && <DoneIcon className={classes.resultIcon} color="secondary" />
      )}
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={() => setPromoCode(code)}
        size="large"
      >
        <SendIcon />
      </IconButton>
    </Paper>
  )
}
