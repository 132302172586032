import React from 'react'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useAuthorizationContext } from './context-provider/authorization-context-provider'

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    color: theme.palette.primary.main,
    padding: '15px 8px',
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 500,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}))

type Props = {
  children: React.ReactNode
}

export const LogoutNavigationBarLink = ({ children }: Props) => {
  const { classes } = useStyles()
  const { logout } = useAuthorizationContext()

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    logout()
  }

  return (
    <a href="src/presentation/common/components/app#" className={classes.button} onClick={handleClick}>
      {children}
    </a>
  )
}
