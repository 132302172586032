import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { Grid, OutlinedInput, InputLabel } from '@mui/material'

import Step from '../Step'
import { isEmailValid, isNullOrEmpty } from '../../../../utils/form-validators'
import { PasswordField } from '../PasswordField'

const useStyles = makeStyles()(() => ({
  emailField: {
    marginTop: 16,
  },
  emailFormTextHelper: {
    fontSize: 20,
  },
}))

export default ({ onChange, emailAlreadyUsed, email, password, ...props }: any) => {
  const { classes } = useStyles()
  const [confirmationPassword, setConfirmationPassword] = useState(null)
  const [state, setState] = useState({
    complet: false,
    invalidEmail: false,
    passwordError: false,
  })

  const handleValueChanged = (name: string) => (event: any) => {
    onChange({
      email: email,
      password: password,
      [name]: event.target.value,
    })
  }

  const validatePasswords = useCallback(() => {
    return password === confirmationPassword
  }, [password, confirmationPassword])

  // Validate form
  useEffect(() => {
    const emailError = !isEmailValid(email)
    const passwordError = !validatePasswords()

    setState({
      complet: !isNullOrEmpty(email) && !isNullOrEmpty(password) && !emailError && !passwordError,
      passwordError: !isNullOrEmpty(password) && !isNullOrEmpty(confirmationPassword) && passwordError,
      invalidEmail: !isNullOrEmpty(email) && emailError,
    })
  }, [email, password, confirmationPassword, setState, validatePasswords])

  return (
    <Step stepLabel="Mon compte" {...props} stepComplete={state.complet}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              error={state.invalidEmail || emailAlreadyUsed}
              className={classes.emailField}
              fullWidth
              required
            >
              <InputLabel htmlFor="username">Email</InputLabel>
              <OutlinedInput
                id="username"
                aria-describedby="username-error-text"
                label="Email"
                required
                type="text"
                autoComplete="username"
                value={email}
                onChange={handleValueChanged('email')}
              />
              <FormHelperText id="username-error-text" className={classes.emailFormTextHelper}>
                {state.invalidEmail
                  ? 'Adresse email invalide'
                  : emailAlreadyUsed
                  ? "L'adresse email est déjà utilisée"
                  : null}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm xs={12}>
            <PasswordField
              id="outlined-adornment-new-password"
              label="Nouveau mot de passe"
              value={password}
              onChange={handleValueChanged('password')}
              required
            />
          </Grid>
          <Grid item sm xs={12}>
            <PasswordField
              id="outlined-adornment-confirm-password"
              label="Confirmer mon nouveau mot de passe"
              value={confirmationPassword || ''}
              onChange={(event: any) => setConfirmationPassword(event.target.value)}
              aria-describedby="confirm-password-error-text"
              error={state.passwordError ? 'Les mots de passe ne correspondent pas' : null}
              required
            />
          </Grid>
        </Grid>
      </form>
    </Step>
  )
}
