import React from 'react'
import { remoteCreateUserFactory } from '../usecases/remote-create-user-factory'
import CreateAccount from '../../../presentation/common/components/app/CreateAccount'

const createCreateAccountPage: React.FC = () => {
  const remoteCreateUser = remoteCreateUserFactory()

  return <CreateAccount createUser={remoteCreateUser} />
}

export default createCreateAccountPage