import React from 'react'
import Step from '@mui/material/Step'
import { makeStyles } from 'tss-react/mui'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

const useStyles = makeStyles()((theme: Theme) =>
  ({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  }),
)

type Props = {
  children: React.ReactNode;
  stepLabel: string;
  stepComplete: boolean;
  handleBack?: any;
  handleNext?: any;
  isFirstStep?: boolean;
  hideButtons?: boolean;
  nextButtonLabel?: string;
  prevButtonLabel?: string;
  disabled?: boolean;
  loading?: boolean;
};

export default ({
                  children,
                  stepLabel,
                  stepComplete,
                  handleBack,
                  handleNext,
                  isFirstStep,
                  hideButtons,
                  nextButtonLabel,
                  prevButtonLabel,
                  disabled = false,
                  loading = false,
                  ...props
                }: Props) => {
  const { classes } = useStyles()

  return (
    <Step {...props}>
      <StepLabel>{stepLabel}</StepLabel>
      <StepContent>
        {children}
        {hideButtons ? null : (
          <div className={classes.actionsContainer}>
            <Button disabled={isFirstStep || disabled} onClick={handleBack} className={classes.button}>
              {prevButtonLabel || 'Précédent'}
            </Button>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={handleNext}
              className={classes.button}
              disabled={!stepComplete || disabled}
              loading={loading}
            >
              {nextButtonLabel || 'Suivant'}
            </LoadingButton>
          </div>
        )}
      </StepContent>
    </Step>
  )
};
