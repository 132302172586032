import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNotificationContext } from '../context-provider/notification-context-provider'
import { useFlyInformationContext } from './FlyInformationContextProvider'
import { useDebounce } from './useDebounce'
import { IGetOptions } from '../../../../../domain/usecases/get-options'

export interface Option {
  id: number
  label: string
  price: number
  mandatory: boolean
  defaultValue: boolean
}

export class OptionContext {
  constructor(
    public readonly id: number,
    public readonly label: string,
    public readonly mandatory: boolean,
    public readonly enabled: boolean,
  ) {
  }
}

export type OptionsContext = {
  isLoading: boolean
  options: readonly OptionContext[]
  enable: (optionId: number) => void
  disable: (optionId: number) => void
}

const OptionsContextClass = createContext<OptionsContext>({
  isLoading: false,
  options: [],
  enable: (_: number) => {
  },
  disable: (_: number) => {
  },
})

export const useOptionsContext = (): OptionsContext => {
  return useContext(OptionsContextClass) as OptionsContext
}

const useAvailableOptions = (getOptions: IGetOptions) => {
  const { notifyError } = useNotificationContext()
  const { deposit, pickup } = useFlyInformationContext()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<readonly Option[]>([])

  useEffect(() => {
    ;(async () => {
      // TODO: make sure deposit > pickup, maybe handle this logic elsewhere
      if (deposit && pickup && !isNaN(deposit.getTime()) && !isNaN(pickup.getTime())) {
        try {
          setLoading(true)
          const response = await getOptions.get({ deposit, pickup })
          setOptions(response)
        } catch (_) {
          setOptions([])
          notifyError('Une erreur est survenue')
        } finally {
          setLoading(false)
        }
      } else {
        setOptions([])
      }
    })()
  }, [deposit, pickup])

  return { isLoading, options }
}

type Props = {
  getOptions: IGetOptions
  children: React.ReactNode
}

export const OptionsContextProvider = ({ getOptions, children }: Props) => {
  const { isLoading, options } = useAvailableOptions(getOptions)
  const [optionContexts, setOptionsContext] = useState<readonly OptionContext[]>([])
  const debouncedOptions = useDebounce<readonly OptionContext[]>(optionContexts, 350)

  useEffect(() => {
    setOptionsContext(
      options.map(
        option => new OptionContext(option.id, option.label, option.mandatory, option.mandatory || option.defaultValue),
      ),
    )
  }, [options])

  const enable = useCallback(
    (optionId: number) => {
      setOptionsContext(
        optionContexts.map(option => {
          return option.id === optionId ? new OptionContext(option.id, option.label, option.mandatory, true) : option
        }),
      )
    },
    [setOptionsContext, optionContexts],
  )

  const disable = useCallback(
    (optionId: number) => {
      setOptionsContext(
        optionContexts.map(option => {
          return option.id === optionId
            ? new OptionContext(option.id, option.label, option.mandatory, option.mandatory)
            : option
        }),
      )
    },
    [setOptionsContext, optionContexts],
  )

  const context: OptionsContext = useMemo(
    () => ({
      isLoading,
      options: debouncedOptions,
      enable,
      disable,
    }),
    [isLoading, debouncedOptions, enable, disable],
  )

  return <OptionsContextClass.Provider value={context}>{children}</OptionsContextClass.Provider>
}
