import React from 'react'
import TextField from '@mui/material/TextField'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'

import DateTimePicker from '../DateTimePicker'
import Step from '../Step'
import { isNullOrEmpty } from '../../../../utils/form-validators'
import { useCreateReservationContext } from './CreateReservationContextProvider'
import { useFlyInformationContext } from './FlyInformationContextProvider'

export default (props: any) => {
  const {
    deposit,
    setDeposit,
    pickup,
    setPickup,
    depositFlyNumber,
    setDepositFlyNumber,
    pickupFlyNumber,
    setPickupFlyNumber,
    destination,
    setDestination,
    origin,
    setOrigin,
  } = useFlyInformationContext()
  const { prices } = useCreateReservationContext()

  function isComplete() {
    // TODO: this logic should be handled by the context provider
    return (
      prices.error == null && prices.isLoading === false &&
      deposit && !isNaN(deposit.getTime()) &&
      pickup && !isNaN(pickup.getTime()) &&
      !isNullOrEmpty(destination) && !isNullOrEmpty(origin)
    )
  }

  return (
    <Step {...props} stepLabel='Parking' stepComplete={isComplete()} loading={prices.isLoading}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1'>Date et heure d'arrivée au parking</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <DateTimePicker
            value={deposit || new Date()}
            minDateTime={dayjs()}
            onChange={d => setDeposit(d.toDate())}
            inputVariant='outlined'
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Numéro de vol'
            variant='outlined'
            fullWidth
            value={depositFlyNumber || ''}
            onChange={event => setDepositFlyNumber(event.target.value)}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Destination'
            variant='outlined'
            fullWidth
            required
            value={destination || ''}
            onChange={event => setDestination(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>Date et heure prévues d'atterrissage</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <DateTimePicker
            value={pickup || new Date()}
            minDateTime={deposit ? dayjs(deposit) : dayjs()}
            onChange={d => setPickup(d.toDate())}
            inputVariant='outlined'
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Numéro de vol'
            variant='outlined'
            fullWidth
            value={pickupFlyNumber || ''}
            onChange={event => setPickupFlyNumber(event.target.value)}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label='Provenance'
            variant='outlined'
            fullWidth
            required
            value={origin || ''}
            onChange={event => setOrigin(event.target.value)}
          />
        </Grid>
        {prices.error && (
          <Grid item xs={12}>
            <Typography variant='body2' color='error' gutterBottom>
              {prices.error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Step>
  )
}
