import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuthorizationContext } from './authorization-context-provider'
import { GetCurrentAccount, IGetCurrentAccount } from '../../../../../domain/usecases/get-current-account'
import { UnauthorizedError } from '../../../../../domain/errors/unauthorized-error'

export type AccountContext = {
  account: GetCurrentAccount.Model | null
  setAccount: (model: GetCurrentAccount.Model) => void
}

const AccountContextClass = createContext<AccountContext>({
  account: null,
  setAccount: () => {
  },
})

export function useAccountContext(): AccountContext {
  return useContext<AccountContext>(AccountContextClass)
}

type Props = {
  account: IGetCurrentAccount
  children: React.ReactNode
}

export const AccountContextProvider = ({ account, children }: Props) => {
  const { logged, logout } = useAuthorizationContext()
  const [currentAccount, setCurrentAccount] = useState<GetCurrentAccount.Model | null>(null)

  useEffect(() => {
    if (logged) {
      ;(async () => {
        try {
          const userAccount = await account.getCurrentAccount()
          setCurrentAccount(userAccount)
        } catch (error) {
          if (error instanceof UnauthorizedError) {
            logout()
          }
        }
      })()
    } else {
      setCurrentAccount(null)
    }
  }, [logged])

  const handleSetAccount = (account: GetCurrentAccount.Model) => {
    setCurrentAccount(account)
  }

  const context: AccountContext = {
    account: currentAccount,
    setAccount: handleSetAccount,
  }

  return <AccountContextClass.Provider value={context}>{children}</AccountContextClass.Provider>
}
