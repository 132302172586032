import React from "react";
import { makeStyles,  } from "tss-react/mui";
import TextField from "@mui/material/TextField";

import Step from "../Step";

const useStyles = makeStyles()((_theme) =>
  ({
    companyField: {
      width: "48%",
      marginRight: 16,
      marginTop: 16,
    },
    serviceField: {
      width: "49%",
      marginTop: 16,
    },
    addressField: {
      width: "48%",
      marginRight: 16,
      marginTop: 16,
    },
    zipcodeField: {
      width: "24%",
      marginRight: 16,
      marginTop: 16,
    },
    cityField: {
      width: "23%",
      marginTop: 16,
    },
  })
);

// TODO: define props type
export default ({ onChange, company, service, address, zipcode, city, ...props }: any) => {
  const { classes } = useStyles();

  // TODO: find event type
  const handleChange = (name: string) => (event: any) => {
    onChange({
      company,
      service,
      address,
      zipcode,
      city,
      [name]: event.target.value,
    });
  };

  return (
    <Step stepLabel="Client professionnel ?" {...props} stepComplete={true}>
      <TextField
        label="Société"
        variant="outlined"
        type="text"
        className={classes.companyField}
        value={company}
        onChange={handleChange("company")}
      />

      <TextField
        label="Service"
        variant="outlined"
        type="text"
        className={classes.serviceField}
        value={service}
        onChange={handleChange("service")}
      />

      <TextField
        label="Adresse de la société"
        type="text"
        variant="outlined"
        className={classes.addressField}
        value={address}
        onChange={handleChange("address")}
      />

      <TextField
        label="Code postal"
        type="text"
        variant="outlined"
        className={classes.zipcodeField}
        value={zipcode}
        onChange={handleChange("zipcode")}
      />

      <TextField
        label="Ville"
        type="text"
        variant="outlined"
        className={classes.cityField}
        value={city}
        onChange={handleChange("city")}
      />
    </Step>
  );
};
