import React, { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'

import { useNotificationContext } from '../context-provider/notification-context-provider'
import { useCreateReservationContext } from './CreateReservationContextProvider'

type PaymentInformation = {
  pendingReservationId: number
  url: string
  body: string
}

export const PaymentButton = props => {
  const { notifyError } = useNotificationContext()
  const { createPendingReservation, isLoading } = useCreateReservationContext()
  const [form, setForm] = useState<PaymentInformation | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const { url, body } = form || {}

  const submit = () => {
    createPendingReservation()
      .then(response => {
        setForm(response)
      })
      .catch(error => {
        console.error(error)
        notifyError('Une erreur est survenue')
      })
  }

  useEffect(() => {
    if (formRef && form) {
      formRef?.current?.submit()
    }
  }, [form, formRef])

  return (
    <>
      <Button variant="contained" color="primary" {...props} onClick={submit} disabled={isLoading}>
        Procéder au paiement sécurisé en ligne
      </Button>
      <form method="POST" id="form" action={url} ref={formRef}>
        <div dangerouslySetInnerHTML={{ __html: body || '' }} />
      </form>
    </>
  )
}
