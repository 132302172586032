import React from 'react'
import {makeStyles,} from 'tss-react/mui'
import {ChangePasswordCard} from './change-password-card'
import {IChangePassword} from "../../../../../domain/usecases/change-password";

const useStyles = makeStyles()(() =>
    ({
        container: {
            maxWidth: '1184px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 16,
        },
    })
)

type Props = {
    changePassword: IChangePassword;
}

export default ({changePassword}: Props) => {
    const {classes} = useStyles()

    return (
        <div className={classes.container}>
            <ChangePasswordCard changePassword={changePassword}/>
        </div>
    )
}
