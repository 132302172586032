import React, { useState } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import { Reservation } from '../../../../../domain/entities/reservation'

const useStyles = makeStyles()(() => ({
    marginTop: {
        marginTop: '16px',
    },
    subtitle: {
        marginLeft: 16,
    },
}))

type Props = {
    reservation: Reservation
    onUpdate: (reservation: Reservation) => void
    onOpenVoucher: () => void
}

export default function ReservationForm({ reservation, onUpdate, onOpenVoucher }: Props) {
    const { classes } = useStyles()
    const [state, setState] = useState({
        depositFlyNumber: reservation.depositFlyNumber || '',
        pickupFlyNumber: reservation.pickupFlyNumber || '',
        destination: reservation.destination || '',
        origin: reservation.origin || '',
        carBrand: reservation.carBrand || '',
        registration: reservation.registration || '',
        lastname: reservation.lastname || '',
        email: reservation.email || '',
        phoneNumber: reservation.phoneNumber || '',
        firstname: reservation.firstname || '',
        parkDays: reservation.parkDays || 0,
    })

    const handleChange = (name: string) => (event: any) => {
        setState({ ...state, [name]: event.target.value })
    }

    const update = () => {
        onUpdate({
            ...reservation,
            ...state,
        })
    }

    return (
      <div>
          <div className={classes.marginTop}>
              <Typography variant="h5" gutterBottom className={classes.subtitle}>
                  Proprietaire du vehicule
              </Typography>
          </div>
          <Grid container spacing={2} className={classes.marginTop}>
              <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    name={'firstname' + reservation.id}
                    fullWidth
                    variant="outlined"
                    label="Prénom"
                    type="text"
                    value={state.firstname || ''}
                    onChange={handleChange('firstname')}
                  />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    name={'lastname' + reservation.id}
                    label="Nom"
                    value={state.lastname || ''}
                    onChange={handleChange('lastname')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    name={'email' + reservation.id}
                    label="Email"
                    value={state.email || ''}
                    onChange={handleChange('email')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    name={'phoneNumber' + reservation.id}
                    label="Numéro de téléphone"
                    value={state.phoneNumber || ''}
                    onChange={handleChange('phoneNumber')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
          </Grid>

          <div className={classes.marginTop}>
              <Typography variant="h5" gutterBottom className={classes.subtitle}>
                  Vehicule
              </Typography>
          </div>

          <Grid container spacing={2} className={classes.marginTop}>
              <Grid item sm xs={12}>
                  <TextField
                    name={'carBrand' + reservation.id}
                    label="Modèle de véhicule"
                    value={state.carBrand || ''}
                    onChange={handleChange('carBrand')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item sm xs={12}>
                  <TextField
                    name={'registration' + reservation.id}
                    label="Immatriculation"
                    value={state.registration || ''}
                    onChange={handleChange('registration')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
          </Grid>

          <div className={classes.marginTop}>
              <Typography variant="h5" gutterBottom className={classes.subtitle}>
                  Details de la réservation
              </Typography>
          </div>

          <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                  <TextField
                    name={'deposit' + reservation.id}
                    label="Date de dépot"
                    value={reservation.deposit ? dayjs(reservation.deposit).format('D MMMM YYYY à HH:mm') : '' || ''}
                    disabled
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item sm={4} xs={12}>
                  <TextField
                    name={'depositFlyNumber' + reservation.id}
                    label="Numéro de vol de départ"
                    value={state.depositFlyNumber || ''}
                    onChange={handleChange('depositFlyNumber')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item sm={4} xs={12}>
                  <TextField
                    name={'destination' + reservation.id}
                    label="Destination"
                    value={state.destination || ''}
                    onChange={handleChange('destination')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item sm={4} xs={12}>
                  <TextField
                    name={'pickup' + reservation.id}
                    label="Date de reprise"
                    value={reservation.pickup ? dayjs(reservation.pickup).format('D MMMM YYYY à HH:mm') : '' || ''}
                    disabled
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item sm={4} xs={12}>
                  <TextField
                    name={'pickupFlyNumber' + reservation.id}
                    label="Numéro de vol de retour"
                    value={state.pickupFlyNumber || ''}
                    onChange={handleChange('pickupFlyNumber')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
              <Grid item sm={4} xs={12}>
                  <TextField
                    name={'origin' + reservation.id}
                    label="Provenance"
                    value={state.origin || ''}
                    onChange={handleChange('origin')}
                    fullWidth
                    variant="outlined"
                  />
              </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.marginTop}>
              <Grid item sm xs={12}>
                  <Button variant="contained" color="primary" onClick={update} fullWidth>
                      Enregistrer les modifications
                  </Button>
              </Grid>
              <Grid item sm xs={12}>
                  <Button variant="contained" color="primary" onClick={onOpenVoucher} fullWidth>
                      Télécharger le bon de commande
                  </Button>
              </Grid>
          </Grid>
      </div>
    )
}
