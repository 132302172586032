import React from 'react'

import CreateReservation from '../../../presentation/common/components/app/CreateReservation'
import { createRemoteQuotes } from '../usecases/remote-quotes-factory'
import { createRemoteCreateReservation } from '../usecases/remote-create-reservation-factory'
import { createRemoteCreatePendingReservation } from '../usecases/remote-create-pending-reservation-factory'
import { createRemoteGetOptions } from '../usecases/remote-get-options-factory'

const createCreateReservationPage: React.FC = () => {
  const remoteQuotes = createRemoteQuotes()
  const remoteGetOptions = createRemoteGetOptions()
  const remoteCreateReservation = createRemoteCreateReservation()
  const remoteCreatePendingReservation = createRemoteCreatePendingReservation()

  return (
    <CreateReservation
      quotes={remoteQuotes}
      getOptions={remoteGetOptions}
      createReservation={remoteCreateReservation}
      createPendingReservation={remoteCreatePendingReservation}
    />
  )
}

export default createCreateReservationPage
