import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import dayjs from 'dayjs'

import { Reservation } from '../../../../../domain/entities/reservation'
import { navigateSafely } from '../../../../utils/safe-navigation'

const statusLabel = new Map<number, string>([
  [0, 'Confirmé'],
  [1, 'Annulé'],
  [2, 'Validé'],
  [3, 'Non présenté'],
  [4, 'Réceptionné'],
  [5, 'Terminé'],
])

const useStyles = makeStyles()(() => ({
  reservationRow: {
    cursor: 'pointer',
  },
  carIcon: {
    maxHeight: 32,
    maxWidth: 32,
  },
}))

type Props = {
  reservations: readonly Reservation[]
}

export const ReservationList = ({ reservations }: Props) => {
  const { classes } = useStyles()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Date de la reservation</TableCell>
            <TableCell align="center">Nombre de jours</TableCell>
            <TableCell align="center">Nom</TableCell>
            <TableCell align="center">Statut</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reservations?.map((reservation: Reservation) => {
            return (
              <TableRow
                hover
                onClick={() => {
                  navigateSafely('/app/reservation', {
                    state: { reservationId: reservation.id },
                  })
                }}
                role="checkbox"
                className={classes.reservationRow}
                tabIndex={-1}
                key={reservation.id}
              >
                <TableCell align="center">
                  <img src="/img/car.png" className={classes.carIcon} width="32" height="32" alt="Logo voiture" />
                </TableCell>
                <TableCell align="center">
                  Réservation du {dayjs(reservation.deposit).format('DD/MM/YYYY')} au{' '}
                  {dayjs(reservation.pickup).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center">{reservation.parkDays} jours</TableCell>
                <TableCell align="center">
                  {reservation.firstname} {reservation.lastname}
                </TableCell>
                <TableCell align="center">{statusLabel.get(reservation.status)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
