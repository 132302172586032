import React, { createContext, useContext, useState } from 'react'
import { getAuthorizationAdapter, setAuthorizationAdapter } from '../../../../../main/adapters/authorization-adpater'
import { Authorization } from '../../../../../domain/entities/authorization'
import { IAuthentication } from '../../../../../domain/usecases/authentication'

export type LoginFunction = (email: string, password: string) => Promise<void>
export type LogoutFunction = () => void

export type AuthorizationContext = {
  logged: boolean
  login: LoginFunction
  logout: LogoutFunction
}

const AuthorizationContextClass = createContext<AuthorizationContext>({
  logged: false,
  login: () => Promise.reject(),
  logout: () => {},
})

export function useAuthorizationContext(): AuthorizationContext {
  return useContext(AuthorizationContextClass)
}

type Props = {
  authentication: IAuthentication
  children: React.ReactNode
}

export const AuthorizationContextProvider = ({ authentication, children }: Props) => {
  const [authorization, setAuthorization] = useState<Authorization | null>(getAuthorizationAdapter())

  const handleLogin = async (email: string, password: string): Promise<void> => {
    const authorization = await authentication.auth({ email, password })

    setAuthorizationAdapter(authorization)
    setAuthorization(authorization)
  }

  const handleLogout = (): void => {
    setAuthorizationAdapter(null)
    setAuthorization(null)
  }

  const context: AuthorizationContext = {
    logged: authorization !== null,
    login: handleLogin,
    logout: handleLogout,
  }

  return <AuthorizationContextClass.Provider value={context}>{children}</AuthorizationContextClass.Provider>
}
