import React from 'react'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import TextField from '@mui/material/TextField'

type DateTimePickerProps = {
  inputVariant?: 'standard' | 'filled' | 'outlined'
  value: Date
  onChange: (Date) => void,
  minDateTime: any
}

const DateTimePicker = ({ inputVariant = 'standard', value, onChange, ...props }: DateTimePickerProps) => (
  <MuiDateTimePicker
    ampm={false}
    inputFormat="DD/MM/YYYY HH:mm"
    renderInput={params => (
      <TextField
        fullWidth
        variant={inputVariant}
        {...params}
        helperText={params.error ? 'Format incorrect (jj/mm/aaaa hh:mm)' : null}
      />
    )}
    value={value}
    onChange={onChange}
    {...props}
  />
)

export default DateTimePicker
