import React, { useState, FunctionComponent } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export interface PasswordFieldProps {
  id: string
  className?: string
  label: string
  value: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  children?: React.ReactNode
  error?: string
  required?: boolean
}

export const PasswordField: FunctionComponent<PasswordFieldProps> = props => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <FormControl variant="outlined" fullWidth className={props.className} required={props.required}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <OutlinedInput
        fullWidth
        id={props.id}
        type={showPassword ? 'text' : 'password'}
        value={props.value}
        onChange={props.onChange}
        aria-describedby={props.id + 'error-text'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={props.label}
      />
      <FormHelperText id={props.id + 'error-text'} error>
        {props.error}
      </FormHelperText>
    </FormControl>
  )
}
