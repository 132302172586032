import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Card, CardContent, Container, Typography } from '@mui/material'

import { ReservationListLoader } from './ReservationListLoader'
import { IGetReservations } from '../../../../../domain/usecases/get-reservations'

const useStyles = makeStyles()(() => ({
  root: {
    marginBottom: 20,
  },
  progressBar: { display: 'flex', justifyContent: 'center' },
}))

type Props = {
  getReservations: IGetReservations
}

export default function index({ getReservations }: Props) {
  const { classes } = useStyles()

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Mes réservations
      </Typography>
      <Card className={classes.root}>
        <CardContent>
          <ReservationListLoader getReservations={getReservations} />
        </CardContent>
      </Card>
    </Container>
  )
}
