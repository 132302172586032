import React from 'react';
import {ForgotPasswordPage} from "../../../presentation/common/components/app/ForgotPassword";
import {createRemoteForgotPassword} from "../usecases/remote-forgot-password-factory";

const createForgotPasswordPage: React.FC = () => {
    const remoteForgotPassword = createRemoteForgotPassword();

    return (
        <ForgotPasswordPage forgotPassword={remoteForgotPassword}/>
    )
};

export default createForgotPasswordPage;