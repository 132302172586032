import MD5 from 'crypto-js/md5'
import { HttpStatusCode } from 'axios'

import { Authentication, IAuthentication } from '../../domain/usecases/authentication'
import { HttpClient } from '../network/http-client'
import { InvalidCredentialsError } from '../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../domain/errors/unexpected-error'

export class RemoteAuthentication implements IAuthentication {
    constructor(private readonly url: string, private readonly httpClient: HttpClient) {
    }

    async auth(params: Authentication.Params): Promise<Authentication.Model> {
        const accessToken = this.createAccessToken(params.email, params.password)
        const httpResponse = await this.httpClient.request({
            url: this.url,
            method: 'get',
            body: params,
            headers: {
                Authorization: 'Basic ' + accessToken,
            },
        })

        switch (httpResponse.statusCode) {
            case HttpStatusCode.Ok:
                return { accessToken }
            case HttpStatusCode.Unauthorized:
                throw new InvalidCredentialsError()
            default:
                throw new UnexpectedError()
        }
    }

    private createAccessToken(email: string, password: string) {
        return btoa(`${email}:${MD5(password)}`)
    }
}
