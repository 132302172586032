import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigateSafely } from '../../../utils/safe-navigation'
import { useAuthorizationContext } from './context-provider/authorization-context-provider'

interface PrivateRouteProps extends RouteComponentProps {
  component: any
}

const PrivateRoute = ({ component: Component, location, ...rest }: PrivateRouteProps) => {
  const { logged } = useAuthorizationContext()

  if (logged === false && location.pathname !== `/app/login`) {
    navigateSafely('/app/login')
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
