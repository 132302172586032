import React from 'react'
import Button from '@mui/material/Button'

import { useCreateReservationContext } from './CreateReservationContextProvider'

export const PaymentAtDepositButton = props => {
  const { createReservation, isLoading } = useCreateReservationContext()

  return (
    <Button variant="contained" color="primary" {...props} onClick={() => createReservation()} disabled={isLoading}>
      Paiement sur place ou en compte
    </Button>
  )
}
