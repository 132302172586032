import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { navigateSafely } from '../../../../utils/safe-navigation'
import { useNotificationContext } from '../context-provider/notification-context-provider'
import Stepper from '../Stepper'
import CreateAccountAccountStep from './AccountStep'
import CreateAccountInformationStep from './InformationStep'
import CreateAccountProfessionelStep from './ProfessionelStep'
import CreateAccountCgvStep from './CgvStep'
import { ICreateAccount } from '../../../../../domain/usecases/create-account'
import { useAuthorizationContext } from '../context-provider/authorization-context-provider'
import { EmailAddressConflictError } from '../../../../../domain/errors/email-address-conflict-error'

const useStyles = makeStyles()(() => ({
  root: {
    maxWidth: 900,
    margin: 'auto',
  },
  confirmationStepContainer: {
    margin: 24,
  },
}))

const ConfirmationStep = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.confirmationStepContainer}>
      <Paper square elevation={0}>
        <Typography>Votre compte a bien été créé.</Typography>
      </Paper>
    </div>
  )
}

type Props = {
  createUser: ICreateAccount
}

// TODO: Add loader when submit and hide confirmation text until response
export default ({ createUser }: Props) => {
  const notificationContext = useNotificationContext()
  const { login } = useAuthorizationContext()
  const { classes } = useStyles()
  const [account, setAccount] = useState({
    email: '',
    password: '',
  })
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    address: '',
    zipcode: '',
    city: '',
    carBrand: '',
    registration: '',
  })
  const [company, setCompany] = useState({
    company: '',
    service: '',
    address: '',
    zipcode: '',
    city: '',
  })
  const [state, setState] = useState({
    stepIndex: 0,
    emailAlreadyUsed: false,
  })

  const onStepperComplete = async () => {
    try {
      await createUser.create({
        email: account.email,
        password: account.password,
        firstname: user.firstname,
        lastname: user.lastname,
        mobile: user.mobile,
        address: user.address,
        zipcode: user.zipcode,
        city: user.city,
        carBrand: user.carBrand,
        registration: user.registration,
        company: company.company,
        companyService: company.company,
        companyAddress: company.address,
        companyZipcode: company.zipcode,
        companyCity: company.city,
      })
      await login(account.email, account.password)
      await navigateSafely('/app')
    } catch (error) {
      if (error instanceof EmailAddressConflictError) {
        setState({ stepIndex: 0, emailAlreadyUsed: true })
      } else {
        setState({ stepIndex: 0, emailAlreadyUsed: false })
        notificationContext.notifyError('Vérifiez votre connexion internet.')
      }
    }
  }

  const onStepIndexChanged = (index: number) => {
    setState({ ...state, stepIndex: index })
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Création de votre compte
          </Typography>
          <Stepper
            stepIndex={state.stepIndex}
            onChange={onStepIndexChanged}
            onComplete={onStepperComplete}
            confirmationStep={ConfirmationStep}
          >
            <CreateAccountAccountStep {...account} emailAlreadyUsed={state.emailAlreadyUsed} onChange={setAccount} />
            <CreateAccountInformationStep {...user} onChange={setUser} />
            <CreateAccountProfessionelStep {...company} onChange={setCompany} />
            <CreateAccountCgvStep nextButtonLabel="Créer mon compte" />
          </Stepper>
        </CardContent>
      </Card>
    </div>
  )
}
